import lookupValueServices from '../../../../services/notisphere/lookup-value'
import _ from 'lodash'
export default {
    data() {
        return {
            item: {
                name: null,
                description: null,
                is_same: null,
                value: null,
            },
            formType: 'INSERT'
        }
    },
    computed: {
        form(){
            if(this.formType == 'INSERT'){
                return {
                    title: 'New Lookup Values'
                }
            }
            if(this.formType == 'UPDATE'){
                return {
                    title: 'Update Lookup Values'
                }
            }
        },
        _value: {
            get() {
                if (this.item.is_same === true) {
                    return this.serializeJson(this.item.value)
                }
            },
            set(val) {
                if (this.item.is_same === true) {
                    this.item.value = this.deserializeJson(val)
                }
            }
        },
    },
    methods: {
        addItem () {
            this.item.value.push({text: null, value: null})
        },
        removeItem (index) {
            this.item.value.splice(index, 1)
        },
        forceRerenderUpdate(name) {
            this.formType = 'UPDATE'
            this.getLookupValue(name)
        },
        async save() {
            var isValid = await this.$refs['observer'].validate()
            if(isValid && this.formType == 'UPDATE'){
                this.updateLookupValue()
            }
        },
        updateLookupValue() {
            lookupValueServices.updateLookupValue(this.item).then(resp =>{
                if (!resp.error){
                    this.$emit('onCompleted', 'UPDATE')
                    this.$refs.modal.hide()
                }
            })
        },
        getLookupValue(name) {
            lookupValueServices.getLookupValue(name).then(resp =>{
                if (!resp.error){
                    this.item = resp.data.d
                    this.$nextTick(()=>{
                        this.$refs.modal.show()
                    })
                }
            })
        },
        deserializeJson(string) {
            try {
                if (string && string != '') {
                    string = string.trim()
                    var arr = string.split('\n')

                    return arr.map( x=>{
                        return {
                            text: x,
                            value: x
                        }
                    })
                }
            } catch (e) {
                //console.log()
            }
            return null
        },
        serializeJson(json) {
            if (json && _.isArray(json) && json.length > 0) {
                var result = ''
                json.forEach((el, i) => {
                    result += el.value + (i == (json.length - 1) ? '' : '\n')
                })
                return result
            }
            return null
        }
    }
}